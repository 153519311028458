import { getAIAutoTagApi } from '@/api/index';
import ShareAiImg from '@/assets/img/share-ai-static.png';
import ShareAiGif from '@/assets/img/share-ai.gif';
import type { CoverAutoTag } from '@/types/share';
export const AiAutoTagDefault = {
  initAi: false,
  title: '',
  podTags: [] as string[],
  tags: [] as string[],
  loading: false,
  shareAiImg: ShareAiImg,
  shareAiGif: ShareAiGif,
};
export const useAiAutoTag = (formState: any, editCover: Ref<string>) => {
  // 获取AI自动生成的标签
  const coverAutoTag = reactive<CoverAutoTag>({ ...AiAutoTagDefault });
  const cover = computed(() => {
    return formState.value.cover;
  });
  watch(cover, () => {
    getAIAutoTagVal(); // 获取AI自动生成的标签
  });
  watch(
    () => coverAutoTag.loading,
    (newValue) => {
      if (newValue && !coverAutoTag.initAi) {
        coverAutoTag.initAi = true;
      }
    },
    { immediate: true },
  );
  const getAIAutoTagVal = async () => {
    try {
      const cover = formState.value.cover;
      if (!cover || cover.indexOf('blob:') !== -1 || cover === editCover.value) {
        // 清空 ｜ 二次编辑首次进入页面不自动生成ai数据
        return;
      }
      coverAutoTag.loading = true;
      const res = await getAIAutoTagApi.fetch({
        cover,
      });
      if (res.code === 0) {
        const { title, podTags = [], tags = [] } = res.data || {};
        if (title && podTags.length && tags.length) {
          coverAutoTag.title = title;
          coverAutoTag.podTags = podTags;
          coverAutoTag.tags = tags;
        }
      }
      coverAutoTag.loading = false;
    } catch (error) {
      console.error('getAIAutoTag-error', error);
      coverAutoTag.loading = false;
    }
  };
  return {
    coverAutoTag,
  };
};
